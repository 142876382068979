.contact-card-container {
  /* background: #11998e; */
  /* background: -webkit-linear-gradient(to right, #ca8cca, #280028); */
  /* background: linear-gradient(to right, #ca8cca, #280028); */
  margin: 0;
  font-family: sans-serif;
}

.contact-card {
  background: white;
  width: 35%;
  height: auto;
  margin: 100px auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.contact-card-header {
  padding: 15px;
  background: linear-gradient(to right, #ca8cca, #280028);
  position: relative;
  top: -50px;
  width: 90%;
  margin: auto;
  border-radius: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.contact-card-footer {
  padding: 15px;
  background: linear-gradient(to right, #ca8cca, #280028);
  position: relative;
  width: 90%;
  margin: auto;
  border-radius: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  bottom: -30px;
}

.header-content {
  text-align: center;
}

.header-content img {
  border-radius: 50%;
}

.contact-card-body {
  margin-top: -40px;
  padding: 15px;
}

.contact-card-header h3 {
  font-family: sans-serif;
  font-size: 30px;
  margin: 10px 0 0;
  color: #fff;
}

.contact-card-header p {
  font-family: sans-serif;
  font-size: 18px;
  margin: 8px 0 0;
  color: #fff;
}

li {
  list-style-type: none;
  display: flex;
}

.contact-card-body li {
  margin: 20px 0;
}

.contact-card-body i {
  font-size: 24px;
  min-width: 24px;
  text-align: center;
}

p {
  margin: 10px 0px;
  color: grey;
}

.contact-card-body ul {
  padding-left: 5px;
}

.li-content {
  margin-left: 20px;
}

.contact-card-footer ul {
  display: flex;
  justify-content: space-around;
  padding-left: 0;
}

.contact-card-footer i {
  font-size: 30px;
}

.download-button {
  text-align: center;
  margin-top: 30px;
}

.download-vcard {
  font-family: sans-serif;
  font-size: 24px;
  padding: 20px 60px;
  width: 70%;
  background: linear-gradient(to right, #ca8cca, #280028);
  box-shadow: 0px 10px 14.1px 0.9px rgba(0, 0, 0, 0.24), 0px 4px 19.6px 0.4px rgba(0, 0, 0, 0.16);
  color: #fff;
  border-radius: 15px;
}

.download-vcard i {
  margin-right: 20px;
}

.contact-title {
  margin: 10px 0 0;
  font-size: 1.17rem;
}

.telephone-text {
  margin-bottom: 0;
}

.li-content .contact-text-header {
  margin-top: 0;
  font-size: 1.17rem;
  color: black;
  font-weight: bold;
}

@media only screen and (max-width:992px) {
  .contact-card {
    width: 80%;
    margin: 50px auto 0;
  }
}

@media only screen and (max-width:480px) {
  .contact-card {
    width: 100%;
  }

  .contact-card-header {
    width: 85%;
  }

  .header-content img {
    width: 40%;
  }

  .hide-mobile {
    display: none;
  }

  .download-vcard i {
    margin: 0;
  }

  .download-vcard {
    padding: 20px 22px;
    border-radius: 50%;
    position: relative;
    z-index: 9999999;
    /* bottom: 70px; */
    width: auto;
    left: 120px;
  }

  .contact-card-footer ul {
    margin: 0;
  }

}

.social-media-link {
  color: #fff
}

.splash-screen {
  text-align: center;
  transition: opacity 5s ease-in-out;
  animation: fadeIn 5s;

}

.splash-screen img {
  max-width: 100%;
  height: auto;
  width: 80%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@media only screen and (min-width:480px) {
  .splash-screen img {
    max-width: 50%;
  }
}