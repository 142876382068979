.const-background {
  /* background-color: #f8f8f9; */
  background: linear-gradient(to left, #ca8cca, #e8d4d4);

}

.const-container {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.const-container img {
  width: 100%;
}