.product-description ul {
  background: #ffecf5;
  padding: 20px;
  padding-left: 40px;
  border-radius: 25px;
  box-shadow: 10px 10px 9px #ddd5d5;
}

.product-description li {
  /* list-style-type: square; */
  /* font-size: 18px; */
  /* align-items: center; */
  line-height: 46px;
}

.product-description ul li::marker {
  color: #ff959c;
  font-size: 30px;
}

.product-description h2 {
  /* text-decoration: underline; */
  text-transform: uppercase;
  font-weight: 800;
}

.product-description i {
  color: #ff959c;
}

.product-description p {
  background: #ffecf5;
  line-height: 46px;
  /* font-size: 18px; */
  padding: 30px;
  border-radius: 25px;
  box-shadow: 10px 10px 9px #ddd5d5;
}

.product-description {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px !important;
  margin-top: 40px !important;
}

.product-container {
  width: 80%;
  margin: auto;
  background-color: #fff;
}

.product-description.paragraph {
  /* flex-direction: row-reverse; */
  margin: auto;
}

.direction-reverse {
  flex-direction: row-reverse;
}

.para-img-div {
  text-align: center;
}

/* Apply some basic styling to the table */
.product-container table {
  border-collapse: collapse;
  width: 100%;
  margin-top: 20px;
}

.product-container th,
.product-container td {
  border: 3px solid #fff;
  background-color: #f2cdf2;
  padding: 10px;
  text-align: left;
  font-size: 18px;
  border-radius: 12px;
}

.product-container th {
  background-color: #f2cdf2;
}

.table-text {
  width: 50%;
}

.img-div {
  width: 40%;
  text-align: center;
}

.img-div img {
  width: -webkit-fill-available;
  /* padding: 40px 20px; */
  border-radius: 15px;
  border: 1px solid #9b8f8f;
  box-shadow: 4px 8px 4px #ddd;
}

.text-div {
  width: 60%;
  padding: 0 30px;
}

@media only screen and (max-width:992px) {}

@media only screen and (max-width:480px) {

  .product-container,
  .table-text,
  .product-description.paragraph {
    width: 95%;
    background-color: white;
  }

  .img-div,
  .text-div {
    width: 100%;
    padding: 10px 0 0;
  }

  .product-description {
    flex-direction: column;
    align-items: center;
  }

  .product-description.bullte-points {
    flex-direction: column;
    align-items: center;
  }

  .product-container ul {
    padding-left: 37px;
  }

  .product-description.paragraph,
  .product-description.table,
  .product-description.bullet-points {
    flex-direction: column-reverse;
  }
}

.icon-align {
  margin: 15px
}